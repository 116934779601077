<script>
	import Layout from '../../layouts/main'
	import appConfig from '@/app.config'
	import PageHeader from '@/components/page-header'
	import Vue from 'vue'
	import axios from 'axios'
	import VueAxios from 'vue-axios'

	Vue.use(VueAxios, axios)
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

	// window.addEventListener("unload", () => {
	// 	localStorage.removeItem('corporateId')
	// 	localStorage.removeItem('isDashboardView')
	// }, false)

	export default {
		middleware: 'authentication',
		page: {
			title: '',
			meta: [
				{
					name: 'description',
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
		},
		data: function() {
			return {
				title: 'Corporate Branch List',
				excelDownloading: false,
				tableData: [],
				totalRows: 1,
				currentPage: 1,
				perPage: 10,
				pageOptions: [10, 25, 50, 100, 500],
				filter: null,
				filterOn: [],
				sortBy: "createdAt",
				sortDesc: true,
				submitted: false,
				isCorporateIdAvailable: false,
				corporateId: null,
				searchQuery: '',
				edit: {
					id: 'edit-modal',
					title: '',
					content: ''
				},
				items: [
					{
						text: 'View',
					},
					{
						text: 'Corporate Branch List',
						active: true,
					},
				],
				isReportTableBusy: false,
				fields: [
					{
						key: 'name',
						sortable: true,
						tdClass: ["align-center", 'align-middle'],
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'address',
						sortable: true,
						tdClass: ["align-center", 'align-middle'],
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					/*{
						key: 'location',
						sortable: true,
						tdClass: ["align-center", 'align-middle'],
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},*/
					{
						key: 'saturdayOff',
						sortable: true,
						tdClass: ["align-center", 'align-middle'],
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'sundayOff',
						sortable: true,
						tdClass: ["align-center", 'align-middle'],
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'actions',
						sortable: false,
						tdClass: ["align-center", 'align-middle'],
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
				],
			};
		},
		computed: {
			rows: function() {
				return this.tableData.length;
			}
		},
		mounted: function() {
			this.totalRows = this.items.length
			this.corporateId = localStorage.getItem('corporateId')
			this.getCorporateBranchList()
		},
		methods: {
			getCorporateBranchList: function() {
				this.isReportTableBusy = true,
				this.axios.post('corporate-branch/list', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
					pageSize: this.perPage,
					page: this.currentPage,
					searchKeyword: this.searchQuery,
				}).then((result) => {
					this.isReportTableBusy = false
					this.tableData = result.data.data.listing
					this.totalRows = result.data.data.totalCounts
				}).catch((error) => {
					alert(error);
				});
			},
			onFiltered: function (filteredItem) {
				this.totalRows = filteredItem.length;
				this.currentPage = 1;
			},
			headers: function() {},
			deleteCorporate: function(item) {
				console.log(item)
			},
			searchFilter: function(value) {
				this.searchQuery = value
				this.getCorporateBranchList()
			},
			changePageSize: function(value) {
				this.perPage = value
				this.getCorporateBranchList()
			},
			changePage: function(value) {
				this.currentPage = value
				this.getCorporateBranchList()
			},
		},
	};
</script>
<style scoped>
	.mr-1 {
		margin-right: 1rem;
	}
	.btn, .btn:hover, .btn:active, .btn:focus {
		background: transparent;
		color: #EB455F;
		outline: 0 none !important;
		box-shadow: none;
	}
	.btn.btn-custom-action {
		border: 0 none;
	}
	textarea {
		resize: none;
	}
	.timing span.to-text {
		margin-left: 20px;
		margin-right: 20px;
	}
	.time-picker.col-md-3 {
		width: 32.5%;
		display: inline-block;
	}
	.vue__time-picker input.display-time {
		width: 100%;
		height: auto;
		padding: 0.47rem 0.75rem;
		font-size: 0.88rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0.25rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	.vue__time-picker input.display-time:focus, .vue__time-picker input.display-time:active {
		border: 1px solid #ced4da;
	}
	.vue__time-picker-dropdown, .vue__time-picker .dropdown {
		top: auto;
	}
	.vue__time-picker-dropdown, .vue__time-picker .dropdown, 
	.vue__time-picker-dropdown .select-list, .vue__time-picker .dropdown .select-list {
		width: 100%;
	}
	.vue__time-picker input.display-time.disabled, .vue__time-picker input.display-time:disabled {
		background: #ededed;
	}
	.timing.set-all span, .timing.set-all div.col-md-3 {
		display: inline-block;
	}
	.edit-link {
		padding: 0.47rem 0.75rem;
		font-size: 19px;
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row mt-4 mb-4">
							<div class="col-sm-12 col-md-4">
								<!-- <div class="btn-group" role="group">
									<button class="btn btn-outline-primary" type="button">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
										<b v-if="excelDownloading">Loading...</b>
									</button>
								</div> -->
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_length" class="dataTables_length">
									<label class="d-inline-flex align-items-center">Show &nbsp;&nbsp;<b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage" size="sm" :options="pageOptions" @change="changePageSize"></b-form-select>&nbsp;&nbsp;&nbsp;entries</label>
								</div>
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_filter" class="dataTables_filter text-md-end">
									<label class="d-inline-flex align-items-center">Search:<b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2" @keyup="searchFilter(filter)"></b-form-input></label>
								</div>
							</div>
						</div>
						<div class="table-responsive mb-0">
							<b-table striped hover outlined bordered :items="tableData" :fields="fields"
								thead-class="bg-transparent" responsive="sm" :per-page="0"
								:current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
								:headers="headers" fixed-header :busy="isReportTableBusy" show-empty>
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
								<!-- <template #empty="scope">
									<h4>{{ scope.emptyText }}</h4>
								</template> -->
								<!--<template v-slot:cell(location)="{ value }">
									<div>{{ value.coordinates.toString().split(',')[0] }}</div>
									<div>{{ value.coordinates.toString().split(',')[1] }}</div>
								</template>-->
								<template v-slot:cell(saturdayOff)="{ value }">
									<div v-if="value === true">
										<i class="mdi mdi-check"></i>
									</div>
									<div v-else>
										<i class="mdi mdi-close"></i>
									</div>
								</template>
								<template v-slot:cell(sundayOff)="{ value }">
									<div v-if="value === true">
										<i class="mdi mdi-check"></i>
									</div>
									<div v-else>
										<i class="mdi mdi-close"></i>
									</div>
								</template>
								<template v-slot:cell(actions)="{ item }">
									<router-link class="mr-1 edit-link" :to="{
											name: 'corporate-branch',
											params: {
												branchId: item._id,
												type: 'edit'
											}
										}">
										<i class="mdi mdi-pencil"></i>
									</router-link>
									<b-button class="btn-custom-action" variant="danger" @click="deleteCorporate(item._id)">
										<i class="mdi mdi-delete"></i>
									</b-button>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
									<ul class="pagination pagination-rounded mb-0">
										<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="changePage"></b-pagination>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>